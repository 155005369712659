<template>
    <div class="mini-cart" @mouseover="triggerIn(); hover = true" @mouseleave="triggerOut(); hover = false">
        <nuxt-link to="/carrinho" aria-label="Carrinho">
            <svg class="icon-minicart" xmlns="http://www.w3.org/2000/svg" width="33.322" height="28.078" viewBox="0 0 33.322 28.078">
                <path class="a" d="M31.909,49.748H25l-3.878-8.879a.976.976,0,1,0-1.789.781l3.537,8.1H10.455l3.537-8.1a.976.976,0,1,0-1.789-.781L8.325,49.748H1.412a1.387,1.387,0,0,0-1.378,1.68l3.64,15.856a1.4,1.4,0,0,0,1.378,1.077H28.268a1.4,1.4,0,0,0,1.378-1.077l3.64-15.856A1.387,1.387,0,0,0,31.909,49.748Zm-23.4,2.994a.977.977,0,0,1-.894-1.367L8.348,49.7h2.13L9.4,52.156a.976.976,0,0,1-.9.586Zm3.271,10.413a.976.976,0,0,1-1.952,0V56a.976.976,0,1,1,1.952,0Zm5.857,0a.976.976,0,0,1-1.952,0V56a.976.976,0,1,1,1.952,0Zm5.857,0a.976.976,0,0,1-1.952,0V56a.976.976,0,1,1,1.952,0ZM25.2,52.66a.975.975,0,0,1-1.285-.5L22.842,49.7h2.13l.733,1.679A.976.976,0,0,1,25.2,52.66Z" transform="translate(0 -40.283)"/>
            </svg>
            <p class="number_orders" v-if="$store.state.order.order.quantidade_total > 0">{{ $store.state.order.order.quantidade_total }}</p>
        </nuxt-link>
        <div v-clickout="hidden">
            <transition name="fade">
                <div class="view-products-cart" v-show="openCart">
                    <div class="title"> Minha cesta de compras</div>
                    <hr class="mt-2">
                    <div class="mini-cart-list">
                        <b-col cols="12" class="product-in-cart mb-2" v-for="product in $store.state.order.order.produtos" :key="product.code">
                            <b-row class="mt-3">
                                <b-col lg="5">
                                    <div class="aspect-ratio" :class="getClassAspectRatio">
                                        <div class="aspect-content">
                                            <nuxt-link :to="product.route">
                                                <LazyLoad image_class="object-fit-cover" :photo="imageUrl(product.foto)" :alt="product.nome" :title="product.nome"/>
                                            </nuxt-link>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col lg="7" class="pl-0">
                                    <div class="name">
                                        <nuxt-link :to="product.route">{{ product.nome }}</nuxt-link>
                                    </div>
                                    <div class="price mt-1">{{ parseFloat(product.quantidade).toFixed(product.unidade_precisao || 0) }}x {{ product.valor_unitario | currency }}</div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between mt-1" v-if="$store.state.order.order.quantidade_total">
                        <div class="subtotal-text"> Subtotal</div>
                        <div class="subtotal-price">{{ $store.state.order.order.valor_total | currency }}</div>
                    </div>
                    <div v-else>
                        <div class="subtotal-text text-center"> Seu carrinho está vazio</div>
                    </div>
                    <div class="d-flex justify-content-center mt-3" v-if="$store.state.order.order.quantidade_total > 0" @click.prevent="openCart = false">
                        <nuxt-link to="/carrinho" class="btn-default btn-square btn-add-cart w-100">
                            IR PARA O CARRINHO
                        </nuxt-link>
                    </div>
                </div>
            </transition>
        </div>
        <b-modal ref="modal-mini-cart" :centered="true" v-model="openModal" hide-footer hide-header-close title="Produto adicionado à cesta de compras!" body-class="pt-0" v-if="$store.state.order.order.quantidade_total > 0">
            <div class="mini-cart-list">
                <b-col cols="12" class="product-in-cart mb-2">
                    <b-row class="mt-3">
                        <b-col cols="5">
                            <div class="aspect-ratio" :class="getClassAspectRatio">
                                <div class="aspect-content">
                                    <nuxt-link :to="lastProduct.route">
                                        <LazyLoad image_class="object-fit-cover" :photo="imageUrl(lastProduct.foto)" :alt="lastProduct.nome" :title="lastProduct.nome"/>
                                    </nuxt-link>
                                </div>
                            </div>
                        </b-col>
                        <b-col class="pl-0 d-flex align-items-center">
                            <div class="name">
                                {{ lastProduct.nome }}
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </div>
            <b-row>
                <b-col sm="6">
                    <b-button @click="$refs['modal-mini-cart'].hide()" class="btn-default btn-square btn-keep-buying w-100 d-block my-2 px-2">
                        CONTINUAR COMPRANDO
                    </b-button>
                </b-col>
                <b-col sm="6">
                    <span @click.prevent="openModal = false" class="my-2 d-block">
                        <nuxt-link to="/carrinho" class="btn-default btn-square w-100 d-block btn-small px-2">
                            IR PARA O CARRINHO
                        </nuxt-link>
                    </span>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import utils from '~/mixins/utils';
    import order from '~/mixins/order';
    import LazyLoad from '~/components/LazyLoad';

    export default {
        components: {
            LazyLoad
        },
        mixins: [
            utils,
            order
        ],
        data() {
            return {
                openCart: false,
                openModal: false,
                hover: false,
            }
        },
        computed: {
            lastProduct() {
                if (this.$store.state.order.order.produtos.length) {
                    return this.$store.state.order.order.produtos[0]
                }
                return {}
            }
        },
        methods: {
            triggerIn() {
                setTimeout(() => {
                    if (this.hover) {
                        this.openCart = true
                    }
                }, 100)
            },
            triggerOut() {
                setTimeout(() => {
                    if (this.hover === false) {
                        this.openCart = false
                    }
                }, 500)
            },
            hidden() {
                this.openCart = false
            }
        },
        mounted() {
            this.$nuxt.$on("openMiniCart", () => {
                this.openCart = true
                this.openModal = window.innerWidth <= 991
                this.hover = true
            });
        }
    }
</script>
