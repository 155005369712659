<template>
    <b-container v-if="error.statusCode === 404" class="py-5">
        <div class="text-center py-5">
            <h2>404 - Página não encontrada</h2>
            <p>Por algum motivo o conteúdo desta página não está disponível no momento ou ela não existe.</p>
            <a href="/" class="btn-default mt-2 d-inline-block py-2 px-4">Página inicial</a>
        </div>
    </b-container>

    <b-container v-else-if="error.statusCode === 200" class="text-center py-5">
        <div style="font-size: 32px;" v-if="error.message && !$wdconfig.modules.general.site_offline" v-html="error.message"></div>
    </b-container>

    <b-container v-else-if="error.statusCode === 503" class="text-center py-5">
        <div style="font-size: 32px;" v-if="error.message && !$wdconfig.modules.general.site_offline" v-html="error.message"></div>
    </b-container>

    <b-container v-else class="text-center py-5">
        <h2>Ocorreu um erro!</h2>
        <p>Tente recarregar a página em breve. Se o erro persistir, entre em contato com o administrador do site.</p>
        <div class="mt-4">
            Código do erro: {{ error.statusCode }}
        </div>
        <div>
            Descrição do erro: {{ error.message }}
        </div>
    </b-container>
</template>

<script>
    export default {
        name: 'nuxt-error',
        layout({app}) {
            if (typeof app.nuxt.err === 'object') {
                try {
                    if (app.nuxt.err.statusCode !== 404) {
                        return 'empty'
                    }
                } catch (e) {
                    console.error(e)
                }
            }
        },
        props: {
            error: {
                type: Object,
                default: () => {
                }
            }
        },
        head() {
            return {
                title: this.$wdconfig.default.title_initial,
                titleTemplate: '%s | ' + this.$wdconfig.default.title_base,
                htmlAttrs: {
                    lang: 'pt-BR'
                },
                meta: [
                    {hid: 'description', name: 'description', content: this.$wdconfig.default.meta.description || ''},
                    {hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image'},
                    {hid: 'og:title', property: 'og:title', content: this.$wdconfig.default.title_base},
                    {hid: 'og:site_name', property: 'og:site_name', content: this.$wdconfig.default.name},
                    {hid: 'og:description', property: 'og:description', content: this.$wdconfig.default.meta.description || ''},
                    {hid: 'og:image', property: 'og:image', content: this.$host + this.$wdconfig.modules.general.social_media_image_share},
                    {hid: 'og:image:width', property: 'og:image:width', content: '600'},
                    {hid: 'og:image:height', property: 'og:image:height', content: '315'},
                    {hid: 'og:url', property: 'og:url', content: this.$host + this.$route.fullPath},
                    {hid: 'msapplication-TileColor', name: 'msapplication-TileColor', content: this.$wdconfig.modules.general.favicon_tilecolor || ''},
                    {hid: 'theme-color', name: 'theme-color', content: this.$wdconfig.modules.general.favicon_theme_color || ''},
                    {hid: 'google-site-verification', name: 'google-site-verification', content: this.$wdconfig.modules.domain_verification.google || ''},
                    {hid: 'facebook-domain-verification', name: 'facebook-domain-verification', content: this.$wdconfig.modules.domain_verification.facebook || ''},
                    {hid: 'pinterest-domain-verification', name: 'p:domain_verify', content: this.$wdconfig.modules.domain_verification.bing || ''},
                    {hid: 'bing-domain-verification', name: 'msvalidate.01', content: this.$wdconfig.modules.domain_verification.bing || ''},
                ],
                link: [
                    {rel: 'apple-touch-icon', type: 'image/png', sizes: '180x180', href: this.$host + '/favicons/apple-touch-icon.png'},
                    {rel: 'icon', type: 'image/png', sizes: '32x32', href: this.$host + '/favicons/favicon-32x32.png'},
                    {rel: 'icon', type: 'image/png', sizes: '16x16', href: this.$host + '/favicons/favicon-16x16.png'},
                    {rel: 'icon', type: 'image/x-icon', href: this.$host + '/favicons/favicon.ico'},
                    {rel: 'manifest', href: this.$host + '/favicons/site.webmanifest'},
                    {rel: 'canonical', href: this.$host + this.$route.path}
                ],
                style: [
                    {
                        hid: 'site-theme',
                        cssText: `:root {${this.$store.getters.cssTheme}}`,
                        type: 'text/css'
                    }
                ]
            }
        },
    }
</script>

