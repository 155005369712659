export default {
    methods: {
        limiterItems(item, limit) {
            let limitedItems
            if (item.length > limit) {
                limitedItems = item.slice(0, limit)
                return limitedItems
            }
            return item
        },
        image_cache(image, cache_image = '') {
            let params = {x: '', y: '', empty: false}
            if (typeof arguments[arguments.length - 1] === 'object' && arguments[arguments.length - 1] !== null) {
                params = Object.assign(params, arguments[arguments.length - 1]);
            }
            if (cache_image) {
                image = cache_image
            }
            return this.imageUrl(image, params.x, params.y, params.empty)
        },
        imageUrl(image, width = 0, height = 0, empty = false) {
            if (image === '') {
                if (empty) {
                    return ''
                }
                image = 'index.php?type=img&id=MA=='
            }
            if (image) {
                image = /^(http|https):\/\//.test(image) ? image : this.$base_api + image
            }
            if (width > 0 || height > 0) {
                image += '&thumb=sim'
            }
            if (width > 0) {
                image += '&x=' + width
            }
            if (height > 0) {
                image += '&y=' + height
            }
            return image;
        },
        fileUrl(url, empty = true) {
            if (url) {
                return /^((http|https):\/\/|\/\/)/.test(url) ? url : this.$config.baseApiURL + url
            }
            if (empty) {
                return ''
            }
            return this.$config.baseApiURL + 'index.php?type=arq&id=MA=='
        },
        imageGallery(image, size) {
            let url = image['image']
            try {
                url = image['image_cache'] ? image['image_cache'] : url
                url = image['image_cache_' + size] ? image['image_cache_' + size] : url
            } catch (e) {
            }
            if (!/^(http|https):\/\//.test(url)) {
                url = this.$config.baseApiURL + url
            }
            return url;
        },
        gallery_cover(gallery, empty) {
            if (gallery) {
                if (gallery.length) {
                    return this.imageUrl(gallery[0].image)
                }
            }
            if (empty) {
                return ''
            }
            return this.$config.baseApiURL + 'index.php?type=img&id=MA=='
        },
        date_last_year(date) {
            if (this.$moment().diff(this.$moment(date), 'years') > 0) {
                if (this.$moment(date).format('mm') > 0) {
                    return this.$moment(date).format('D [de] MMMM [de] YYYY, H[h]mm')
                }
                return this.$moment(date).format('D [de] MMMM [de] YYYY, HH:mm')
            } else {
                if (this.$moment(date).format('mm') > 0) {
                    return this.$moment(date).format('D [de] MMMM, H[h]mm')
                }
                return this.$moment(date).format('D [de] MMMM, H[h]')
            }
        },
        date_simple_last_year(date, format = 'DD/MM/YYYY') {
            if (this.$moment().diff(this.$moment(date, format), 'years') > 0) {
                return this.$moment(date, format).format('D [de] MMMM [de] YYYY')
            } else {
                return this.$moment(date, format).format('D [de] MMMM')
            }
        },
        query(params, remove_params = []) {
            let new_query = {}
            for (let prop in this.$route.query) {
                if (!remove_params.includes(prop)) {
                    new_query[prop] = this.$route.query[prop];
                }
            }
            let query = Object.assign({}, new_query, params)
            this.$router.push({query: query})
        },
        url_redirect(url, target = '_top') {
            if (target === '_blank') {
                window.open(url)
                return true
            }
            if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0 || url.indexOf('//') === 0) {
                window.location = url
            } else {
                this.$router.push(url)
            }
        },
        show_login() {
            $('#loginModal').modal('show')
        },
        notCopyContent: function (e) {
            e.preventDefault();
        },
        truncate(value, limit, strip_tags = false) {
            if (strip_tags) {
                value = value.replace(/<[^>]+>/g, '');
            }
            if (!value) {
                return ''
            }
            if (value.length > limit) {
                value = value.substring(0, limit).trim() + '...';
            }
            return value
        },
        getRecaptchaWidgetId(ref) {
            try {
                return this.$refs[ref].$el.querySelector(`textarea.g-recaptcha-response`).id.replace(/\D/g, '') || 0
            } catch (e) {
                console.error(e)
            }
            return 0
        },
    },
    computed: {
        getClassAspectRatio() {
            const list = {
                '1:1': 'ratio-1-1',
                '2:3': 'ratio-2-3',
                '4:3': 'ratio-4-3',
                '3:4': 'ratio-3-4',
                '16:9': 'ratio-16-9',
                '3:2': 'ratio-3-2',
                '8:5': 'ratio-8-5',
                '21:9': 'ratio-21-9',
                '7:1': 'ratio-7-1',
                '9:1': 'ratio-9-1',
                '13:1': 'ratio-13-1',
                '26:1': 'ratio-26-1'
            }
            if (this.$wdconfig.modules.product.image_aspect_ratio in list) {
                return list[this.$wdconfig.modules.product.image_aspect_ratio]
            }
            return list['1:1']
        }
    },
    filters: {
        truncate: function (value, limit) {
            if (!value) {
                return ''
            }
            if (value.length > limit) {
                value = value.substring(0, limit).trim() + '...';
            }
            return value
        },
        striphtml: function (value) {
            let regex = /(<([^>]+)>)/ig;
            return value.replace(regex, '')
        }
    }
}
