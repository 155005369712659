<template>
    <footer class="footer position-relative">
        <div class="info">
            <b-container>
                <b-row>
                    <b-col cols="12" lg="6" v-if="cards.length">
                        <div class="title mb-2 text-uppercase"> Pague com</div>
                        <b-row>
                            <b-col cols="4" lg="1" v-for="card in cards" :key="card.id" class="mb-2 mr-2 ml-2">
                                <img :src="imageUrl(card.image1)" width="56" height="34" :alt="card.name" :title="card.name" class="payments_info">
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" lg="6">
                        <div class="title mb-2 text-uppercase"> Certificado e segurança</div>
                        <b-row>
                            <b-col cols="6" lg="3" v-for="certified in certificates" :key="certified.name" class="d-flex justify-content-center mb-2 pl-2 pr-2">
                                <component :is="certified.link ? 'a' : 'div'" v-bind="certified.link ? {href: certified.link, target: certified.target} : ''" @click.prevent="certified.link ? url_redirect(certified.link, certified.target) : ''" class="certifieds_info d-flex align-items-center">
                                    <img :src="certified.image" :alt="certified.name" :title="certified.name" class="my-auto p-3 img-fluid mx-auto">
                                </component>
                            </b-col>
                        </b-row>
                    </b-col>
                    <div class="line_horizontal mt-3"></div>
                    <b-col cols="12" md="6" lg="3" class="mt-3">
                        <div class="title mb-1"> Fale conosco</div>
                        <div class="text mb-1">
                            <span v-if="$wdconfig.default.address.street">{{ $wdconfig.default.address.street }}</span>
                            <span v-if="$wdconfig.default.address.number">{{ $wdconfig.default.address.number }}</span>
                            <span v-if="$wdconfig.default.address.neighborhood"> - {{ $wdconfig.default.address.neighborhood }}</span>
                        </div>
                        <div class="text mb-1">
                            <span v-if="$wdconfig.default.address.city">{{ $wdconfig.default.address.city }}</span>
                            <span v-if="$wdconfig.default.address.state"> / {{ $wdconfig.default.address.state }}</span>
                        </div>
                        <div class="text mb-1" v-if="$wdconfig.default.phone"> Fone: {{ $wdconfig.default.phone }}</div>
                        <div class="text" v-if="$wdconfig.default.email"> {{ $wdconfig.default.email }}</div>
                        <div class="text mt-3 font-weight-bold" v-if="$wdconfig.default.text_service_hours"> Horário de atendimento</div>
                        <div class="text" v-if="$wdconfig.default.text_service_hours"> {{ $wdconfig.default.text_service_hours }}</div>
                        <div class="mt-3">
                            <nuxt-link to="/contato" class="btn-call-to-us text-uppercase"> Fale conosco </nuxt-link>
                        </div>
                    </b-col>
                    <b-col cols="12" md="6" lg="3" class="mt-3" v-if="categories.length">
                        <div class="title mb-1 text-uppercase"> Categorias</div>
                        <div class="text info-list mb-1" v-for="category in limiterItems(categories, 9)">
                            <a :href="category.route">{{ category.name }}</a>
                        </div>
                        <div v-if="categories.length > 9">
                            <a href="/categorias" class="text see-more-categories"> Ver mais categorias </a>
                        </div>
                    </b-col>
                    <b-col cols="12" md="6" lg="3" class="mt-3" v-for="(info, index) in footerMenu" :key="info.id">
                        <div class="title mb-1 text-uppercase"> {{ info.name }}</div>
                        <div v-if="info.subitems.length">
                            <div class="text info-list mb-1" v-for="subitem in info.subitems">
                                <a :href="subitem.href" :target="subitem.target" @click="lgpdCheck(subitem.href, $event)"> {{ subitem.name}}</a>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div class="all-rights-reserved position-relative">
            <b-container>
                <div class="d-flex justify-content-between align-items-center pt-3 pb-3">
                    <div class="social-reason text-uppercase"> {{ $wdconfig.default.copyright }}</div>
                    <div>
                        <a href="https://www.wdshop.com.br/" target="_blank" :rel="$route.path === '/' ? '' : 'nofollow'" title="WD House - Somos digitais">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1276.66 251.28" width="100" height="19" fill="#FFF">
                                <g>
                                    <g>
                                        <path d="M180.55,70.89v113a21,21,0,0,1-1.82,8.7,23.72,23.72,0,0,1-4.89,7.12,22.58,22.58,0,0,1-16,6.54,21.84,21.84,0,0,1-8.7-1.74,22.47,22.47,0,0,1-7.12-4.8,23.63,23.63,0,0,1-4.89-7.12,20.94,20.94,0,0,1-1.82-8.7v-113H90.27v113a21.82,21.82,0,0,1-6.63,15.82c-.24.23-.49.43-.73.65,6,14.49,18.35,24.64,28.5,34.73.4-.35.82-.65,1.22-1a66.77,66.77,0,0,0,21,12.84,67.75,67.75,0,0,0,24.18,4.39,66.27,66.27,0,0,0,26.42-5.3,68.85,68.85,0,0,0,21.53-14.41,67.2,67.2,0,0,0,14.49-21.45,65.82,65.82,0,0,0,5.3-26.25v-113Z"/>
                                        <path style="opacity: 0.7" d="M82.91,200.33a23.31,23.31,0,0,1-6.55,4.15,22.45,22.45,0,0,1-8.78,1.74,21.84,21.84,0,0,1-8.7-1.74A22.5,22.5,0,0,1,47,192.56a21.83,21.83,0,0,1-1.74-8.7v-113H0v113a65.77,65.77,0,0,0,5.3,26.25,67.16,67.16,0,0,0,14.49,21.45A69.06,69.06,0,0,0,41.24,246a65.66,65.66,0,0,0,26.34,5.3,67.56,67.56,0,0,0,24.27-4.39,64.92,64.92,0,0,0,19.57-11.82C101.27,225,88.91,214.82,82.91,200.33Z"/>
                                        <path d="M402.48,248H391.55L374,223.61a145.05,145.05,0,0,1-13.67,10.85,101,101,0,0,1-15.16,8.78,93,93,0,0,1-16.32,5.88,67.89,67.89,0,0,1-17,2.15,89.32,89.32,0,0,1-64-26.59A91.84,91.84,0,0,1,228.39,195a96.39,96.39,0,0,1-7.12-37.35,96.57,96.57,0,0,1,7.12-37.27,92.65,92.65,0,0,1,19.46-29.82,89.85,89.85,0,0,1,28.82-19.71,87.78,87.78,0,0,1,35.2-7.12,80.14,80.14,0,0,1,12.34,1A64.32,64.32,0,0,1,336.64,68,51.56,51.56,0,0,1,348,73.88a34.52,34.52,0,0,1,8.94,8.94V0h45.55Zm-45.55-90.27a47,47,0,0,0-3.56-18,51.73,51.73,0,0,0-9.69-15.4,48.2,48.2,0,0,0-14.33-10.77,38.9,38.9,0,0,0-17.48-4.06,46.23,46.23,0,0,0-17.48,3.31,41.17,41.17,0,0,0-14.25,9.52,44.55,44.55,0,0,0-9.52,15.16,55.41,55.41,0,0,0-3.48,20.21,50.48,50.48,0,0,0,13,34,44.38,44.38,0,0,0,14.25,10.44,41,41,0,0,0,17.48,3.81,38.86,38.86,0,0,0,17.48-4.06,48.16,48.16,0,0,0,14.33-10.77,51.73,51.73,0,0,0,9.69-15.4A47,47,0,0,0,356.93,157.69Z"/>
                                        <path d="M482.15,64.67V0H436.93V85.45c15-6.28,33-9.75,45.32-20.85Z"/>
                                        <path d="M589.24,113a81.53,81.53,0,0,0-16.9-25.34,78.38,78.38,0,0,0-25.09-17.06,77,77,0,0,0-30.81-6.21,61.2,61.2,0,0,0-17.56,2.57,52.78,52.78,0,0,0-16.63,8.63c-12.33,11.1-30.34,14.56-45.32,20.85V245.23h45.22V143.86a33.2,33.2,0,0,1,2.65-13.17,34.12,34.12,0,0,1,31.64-20.79,32.63,32.63,0,0,1,13.09,2.65,34.14,34.14,0,0,1,18.05,18.14,33.24,33.24,0,0,1,2.65,13.17h-.33V245.23h45.55V143.86A77.59,77.59,0,0,0,589.24,113Z"/>
                                        <path d="M779.87,157.69a95.57,95.57,0,0,1-7.12,37,92.6,92.6,0,0,1-19.38,29.65,90.68,90.68,0,0,1-28.74,19.71,90,90,0,0,1-70.56,0,90.79,90.79,0,0,1-48.28-49.36,95.52,95.52,0,0,1-7.12-37,97,97,0,0,1,7.12-37.43,91.67,91.67,0,0,1,19.46-29.73,90.52,90.52,0,0,1,28.82-19.63,87.78,87.78,0,0,1,35.2-7.12,92.91,92.91,0,0,1,35.36,6.71,85.49,85.49,0,0,1,28.74,19,90.29,90.29,0,0,1,19.38,29.65Q779.87,136.49,779.87,157.69Zm-45.55,0a52.67,52.67,0,0,0-3.56-19.79,47.11,47.11,0,0,0-9.69-15.24,41.93,41.93,0,0,0-14.33-9.77,46.66,46.66,0,0,0-35,0,41.07,41.07,0,0,0-14.25,9.77A46.62,46.62,0,0,0,648,137.9a53.78,53.78,0,0,0-3.48,19.79,51.24,51.24,0,0,0,3.48,19A47.77,47.77,0,0,0,657.55,192a44.55,44.55,0,0,0,14.25,10.19,41.73,41.73,0,0,0,17.48,3.73,44.28,44.28,0,0,0,17.48-3.48,43.48,43.48,0,0,0,14.33-9.77,45.91,45.91,0,0,0,9.69-15.24A52.84,52.84,0,0,0,734.32,157.69Z"/>
                                        <path d="M916.09,227.26a121.88,121.88,0,0,1-11.68,9.36,87.82,87.82,0,0,1-13,7.62,80.53,80.53,0,0,1-14,5.13,57.16,57.16,0,0,1-14.66,1.9,81.8,81.8,0,0,1-30.81-5.8A74.86,74.86,0,0,1,789.63,203q-6.21-15.32-6.21-34.37V70.56h45.05v98.06a43.9,43.9,0,0,0,2.73,16,35,35,0,0,0,7.37,11.84,30.8,30.8,0,0,0,10.85,7.29,35.57,35.57,0,0,0,13.33,2.48A28.15,28.15,0,0,0,875.93,203a36.94,36.94,0,0,0,10.85-8.53,40.94,40.94,0,0,0,7.29-12,37.36,37.36,0,0,0,2.65-13.83V70.56h45.22V248H931Z"/>
                                        <path d="M1054.63,248H951.27V202.91h103.36a11.31,11.31,0,0,0,11.26-11.26q0-4.8-3.31-6.29a19.29,19.29,0,0,0-8-1.49h-47a54.56,54.56,0,0,1-22-4.47,56.64,56.64,0,0,1-29.9-30.15,57.48,57.48,0,0,1,0-44.06,55.83,55.83,0,0,1,29.9-29.9,55.46,55.46,0,0,1,22-4.39h91.6v45.05h-91.6a11.31,11.31,0,0,0-11.26,11.26,11.3,11.3,0,0,0,3.31,8.2,10.71,10.71,0,0,0,8,3.4h47a61.21,61.21,0,0,1,21.86,3.89,55.32,55.32,0,0,1,17.89,10.85,51.29,51.29,0,0,1,12.09,16.73,50.74,50.74,0,0,1,4.47,21.37,54,54,0,0,1-4.47,21.95,58.83,58.83,0,0,1-12.09,17.89,55.65,55.65,0,0,1-17.89,12.09A55,55,0,0,1,1054.63,248Z"/>
                                        <path d="M1191.85,204.57a25.72,25.72,0,0,0,5.3,1.08q2.65.25,5.3.25a44.5,44.5,0,0,0,12.75-1.82,46,46,0,0,0,11.51-5.22,42.87,42.87,0,0,0,9.61-8.28,43.52,43.52,0,0,0,6.87-10.85L1276.33,213a89.23,89.23,0,0,1-14.49,16.07,91.38,91.38,0,0,1-17.72,12.09,88.53,88.53,0,0,1-20,7.54,90.94,90.94,0,0,1-21.62,2.57,89.55,89.55,0,0,1-64-26.34A90.9,90.9,0,0,1,1119,195.37a97.26,97.26,0,0,1-7.12-37.68,100.48,100.48,0,0,1,7.12-38.43,86.84,86.84,0,0,1,48.28-48.7,91.5,91.5,0,0,1,35.2-6.79,89.19,89.19,0,0,1,21.7,2.65,90.82,90.82,0,0,1,37.93,19.79,90,90,0,0,1,14.58,16.15ZM1215,111.31a26.14,26.14,0,0,0-6.21-1.49,59.84,59.84,0,0,0-6.38-.33,45.22,45.22,0,0,0-17.48,3.4,41.63,41.63,0,0,0-14.25,9.69,45.66,45.66,0,0,0-9.52,15.16,54.26,54.26,0,0,0-3.48,20q0,2.48.25,5.63t.83,6.38q.58,3.23,1.41,6.21a23,23,0,0,0,2.15,5.3Z"/>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </b-container>
            <WhatsAppButton class="btn-whatsapp-container fixed" id="btn-whatsapp-container"/>
        </div>
    </footer>
</template>

<script>
    import utils from '~/mixins/utils';
    import lgpd from "~/mixins/lgpd";
    import WhatsAppButton from "~/components/WhatsAppButton";

    export default {
        mixins: [
            utils,
            lgpd
        ],
        components: {
            WhatsAppButton
        },
        data() {
            return {
                categories: [],
                cards: [],
                footerMenu: []
            }
        },
        mounted() {
            if (process.client) {
                $(window).scroll(function () {
                    var $footer = $('.all-rights-reserved');
                    var $btn_whatsapp = $($footer).find('#btn-whatsapp-container');
                    if ($(window).scrollTop() + window.innerHeight > $(document).height() - $footer.outerHeight()) {
                        $btn_whatsapp.addClass('bottom').css('bottom', $footer.outerHeight());
                    } else {
                        $btn_whatsapp.removeClass('bottom').css('bottom', 0);
                    }
                });
            }
        },
        computed: {
            certificates() {
                return [
                    {
                        name: 'Google',
                        link: `https://transparencyreport.google.com/safe-browsing/search?hl=pt-PT&url=${this.$host}`,
                        target: '_blank',
                        image: '/img/certificates/security-site.png',
                        width: 125,
                        height: 64
                    }, {
                        name: 'Site Seguro',
                        link: null,
                        target: '_blank',
                        image: '/img/certificates/security-site-2.png',
                        width: 125,
                        height: 75
                    }, {
                        name: 'letsEncrypt',
                        link: null,
                        target: '_blank',
                        image: '/img/certificates/letsEncrypt.png',
                        width: 95,
                        height: 81
                    }, {
                        name: 'SSL',
                        link: null,
                        target: '_blank',
                        image: '/img/certificates/selo-ssl-blindado.png',
                        width: 108,
                        height: 83
                    }
                ]
            }
        },
        async fetch() {
            this.categories = this.$store.state.categories

            this.cards = await this.$axios.$get(`/api/v1/cms/?category_code=4&limit=100`).then((res) => {
                return res.data
            }).catch((e) => {
                console.error(e)
            })

            this.footerMenu = this.$store.state.menu_footer
        }
    }
</script>
