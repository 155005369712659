import { extend, localize, configure } from "vee-validate";
import { required, required_if, email, min, regex, confirmed, size } from "vee-validate/dist/rules";
import pt_BR from 'vee-validate/dist/locale/pt_BR.json';
import cpfValidate from './vee-validate-rules/vee-validate-cpf';
import cnpjValidate from './vee-validate-rules/vee-validate-cnpj';
import passwordValidate from './vee-validate-rules/vee-validate-password';
import dateValidate from './vee-validate-rules/vee-validate-date';
import datetimeValidate from './vee-validate-rules/vee-validate-datetime';
import fullnameValidate from './vee-validate-rules/vee-validate-fullname';
import numberpositiveValidate from './vee-validate-rules/vee-validate-numberpositive';
import dateAgeValidate from './vee-validate-rules/vee-validate-date-age';

// Vue.use(extend, {
//   inject: true
// });

configure({
    classes: {
        valid: 'is-valid',
        invalid: 'is-invalid'
    }
})

// Install required rule.
extend("required", required);

// Install required rule.
extend("required_if", required_if);

// Install email rule.
extend("email", email);

// Install min rule.
extend("min", min);

// Install regex rule.
extend("regex", regex);

// Install size rule.
extend("size", size);

extend("confirmed", confirmed);

extend('cpf', (value) => {
    if (cpfValidate(value)) {
        return true;
    }
    return 'O campo {_field_} é inválido.';
});

extend('cnpj', (value) => {
    if (cnpjValidate(value)) {
        return true;
    }
    return 'O campo {_field_} é inválido.';
});

extend('password', (value) => {
    if (passwordValidate(value)) {
        return true;
    }
    return 'O campo {_field_} deve conter no mínimo 6 caracteres, com letras e pelo menos 1 número.';
});

extend('date', (value) => {
    if (dateValidate(value)) {
        return true;
    }
    return 'O campo {_field_} é inválido.';
});

extend('datetime', (value) => {
    if (datetimeValidate(value)) {
        return true;
    }
    return 'O campo {_field_} é inválido.';
});

extend('fullname', (value) => {
    if (fullnameValidate(value)) {
        return true;
    }
    return 'O campo {_field_} deve conter o nome completo.';
});

extend('numberpositive', (value) => {
    if (numberpositiveValidate(value)) {
        return true;
    }
    return 'O campo {_field_} é inválido.';
});

extend('dateage', (value) => {
    if (!dateValidate(value)) {
        return 'O campo {_field_} é inválido.';
    }
    if (dateAgeValidate(value)) {
        return true
    }
    return 'Para fazer o cadastro você deve ter mais que 18 anos.';
});

localize('pt_BR', pt_BR);
