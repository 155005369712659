<template>
    <b-col cols="12" :lg="megamenu ? 6 : 12" v-if="product && product.code">
        <div class="megamenu-showcase-title mb-3" v-if="megamenu">DESTAQUE</div>
        <div class="product-card">
            <nuxt-link :to="product.route">
                <b-row :style="megamenu ? '' : 'align-items: center;'">
                    <b-col :lg="megamenu ? 6 : 6" :class="megamenu ? 'pb-3' : ''">
                        <div class="aspect-ratio mb-2" :class="getClassAspectRatio">
                            <div class="aspect-content" v-if="product.gallery.length">
                                <img
                                    :class="$wdconfig.modules.product.image_object_fit"
                                    :src="image_cache(product.gallery[0].image, product.gallery[0].image_cache)"
                                    v-show="multiple_images === false || !onhover"
                                />
                                <img
                                    :class="$wdconfig.modules.product.image_object_fit"
                                    :src="image_cache(product.gallery[1].image, product.gallery[1].image_cache)"
                                    v-show="onhover"
                                    v-if="multiple_images"
                                />
                            </div>
                            <div class="aspect-content" v-else>
                                <img :class="$wdconfig.modules.product.image_object_fit" :src="imageUrl('')">
                            </div>
                        </div>
                    </b-col>
                    <b-col :lg="megamenu ? 6 : 6" class="px-4 pl-lg-0 pr-lg-3">
                        <div class="name mb-2">
                            <div v-html="truncate(product.name, 60)"></div>
                            <div v-if="view_code"><small>Cód.: {{ view_code }}</small></div>
                        </div>
                        <div class="product-prices pb-2">
                            <div v-if="product.active == 'yes'">
                                <div class="per-quantity" v-if="product.promotions.length">
                                    <svg class="discount-svg" xmlns="http://www.w3.org/2000/svg" width="12.259" height="12.259" viewBox="0 0 12.259 12.259">
                                        <g transform="translate(-0.002)">
                                            <path class="a" d="M11.962,6.514,6.176.736A2.506,2.506,0,0,0,5.351.214,2.5,2.5,0,0,0,4.4,0H1.038A1,1,0,0,0,.309.307,1,1,0,0,0,0,1.036V4.4a2.5,2.5,0,0,0,.214.947,2.411,2.411,0,0,0,.522.817L6.524,11.96a.991.991,0,0,0,.728.3,1.036,1.036,0,0,0,.736-.3l3.973-3.981a.991.991,0,0,0,.3-.728A1.037,1.037,0,0,0,11.962,6.514ZM3.324,3.322a1,1,0,0,1-.732.3A1.036,1.036,0,0,1,1.556,2.589,1.036,1.036,0,0,1,2.591,1.554,1.036,1.036,0,0,1,3.627,2.589,1,1,0,0,1,3.324,3.322Z" transform="translate(0)"/>
                                        </g>
                                    </svg>
                                    <div class="mytooltip">
                                        <div class="text-left">Economize</div>
                                        <div v-for="promotion in product.promotions" :key="promotion.price">A partir de
                                            {{promotion.quantity_initial}} {{product.unit.name}}<span class="price"> {{promotion.price | currency}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="price_old" v-if="product.price_old !== '0.00'">
                                    {{ product.price_old | currency }}
                                </div>
                                <div class="d-flex align-items-baseline">
                                    <div class="price">
                                        {{ product.price | currency }}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center" v-if="product.payment_condition && product.payment_condition.installments > 1">
                                    <div class="condition-payment"> ou</div>
                                    <div class="ml-1 highlight">
                                        {{product.payment_condition.installments}} x {{(product.price/product.payment_condition.installments) | currency}}
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="mb-2 unavailable">Indisponível</div>
                            </div>
                        </div>

                        <div class="d-flex flex-wrap">
                            <div class="discount-porcentage in-showcase mr-2 mb-2" v-if="percentage"> -{{ percentage }}%</div>
                            <div class="tag mr-2 mb-2" :style="megamenu ? '' : 'padding: 2px 7px;'" v-for="tag in product.tags.list" :key="tag.id"> {{ tag.tag }}</div>
                        </div>
                    </b-col>
                </b-row>
            </nuxt-link>
        </div>
    </b-col>
</template>

<script>
    import utils from '~/mixins/utils'
    import LazyLoad from "~/components/LazyLoad";

    export default {
        mixins: [utils],
        components: {
            LazyLoad
        },
        props: [
            'product',
            'megamenu'
        ],
        data() {
            return {
                hover: false,
                focus: false,
            }
        },
        computed: {
            uid() {
                return this._uid
            },
            onhover() {
                return this.hover || this.focus
            },
            multiple_images() {
                return !!(typeof this.product.gallery === 'object' && this.product.gallery.length > 1 && this.$wdconfig.modules.product.change_image_hover_in_listing)
            },
            view_code() {
                try {
                    if (this.$wdconfig.modules.product.code_in_listing === 'codigo_integracao') {
                        if (this.product.integration_code !== '' && this.product.integration_code !== '0') {
                            return this.product.integration_code
                        }
                    }
                    if (this.$wdconfig.modules.product.code_in_listing === 'codigo_barras') {
                        if (this.product.barcode !== '' && this.product.barcode !== '0') {
                            return this.product.barcode
                        }
                    }
                } catch (e) {
                    console.error(e)
                }
                return this.product.code
            },
            percentage() {
                let result = 0
                if (this.product.active === 'yes' && parseFloat(this.product.price_old)) {
                    result = Math.round((((this.product.price * 100) / this.product.price_old) - 100) * -1)
                }
                return result
            }
        },
    }
</script>
