const cookieparser = process.server ? require('cookieparser') : undefined
const Cookie = process.client ? require('js-cookie') : undefined
import wdconfig from '~/data/config.json';
import {merge} from 'lodash';

export const getters = {
    isAuth: state => {
        return !!state.auth.access_token
    },
    getWdConfig: state => {
        return state.config
    },
    cssTheme: state => {
        let theme = '';
        try {
            if (state.config) {
                for (let prop in state.config.theme) {
                    theme += `--${prop}: ${state.config.theme[prop]};`
                }
            }
        } catch (e) {
            console.error(e)
        }
        return theme
    },
    isMaintenanceMode: state => {
        return !!state.maintenance_mode
    }
}
export const state = () => {
    return {
        config: wdconfig,
        categories: {},
        menu_site: [],
        menu_footer: [],
        maintenance_mode: false,
        auth: {
            access_token: '',
            person_name: ''
        }
    }
}
export const mutations = {
    setConfig(state, config) {
        state.config = config
    },
    setCategories(state, categories) {
        state.categories = categories
    },
    setMenuFooter(state, menu) {
        state.menu_footer = menu
    },
    setMenuSite(state, menu) {
        state.menu_site = menu
    },
    setMaintenanceMode(state, status) {
        state.maintenance_mode = status
    },
    setAuth(state, auth = {
        access_token: '',
        person_name: ''
    }) {
        state.auth = auth
    },
}
export const actions = {
    async nuxtServerInit({commit, state}, {req, app}) {
        try {
            await app.$axios.$get(`index.php?p=controller_settings_general_api_v1`).then((res) => {
                merge(wdconfig, res.data.config);
                commit('setConfig', wdconfig)
            }).catch(e => {
                console.error(e)
                commit('setConfig', wdconfig)
            })

            await app.$axios.$get(`api/v1/ecommerce/categories`).then(res => {
                if (res.status === 'success' && res.data) {
                    commit('setCategories', res.data)
                }
            }).catch(e => {
                commit('setCategories', {})
                console.error(e)
            })

            await app.$axios.$get(`/api/v1/menu/?area=2`).then(res => {
                if (res.status === 'success' && res.data) {
                    commit('setMenuSite', res.data)
                }
            }).catch(e => {
                commit('setMenuSite', [])
                console.error(e)
            })

            await app.$axios.$get(`/api/v1/menu/?area=3`).then(res => {
                if (res.status === 'success' && res.data) {
                    commit('setMenuFooter', res.data)
                }
            }).catch(e => {
                commit('setMenuFooter', [])
                console.error(e)
            })

        } catch (e) {
            commit('setConfig', wdconfig)
            console.error(e)
        }
        let auth = {
            access_token: '',
            person_name: ''
        }
        if (req.headers.cookie) {
            const parsed = cookieparser.parse(req.headers.cookie)
            try {
                auth = JSON.parse(parsed.auth)
            } catch (err) {
                // No valid cookie found
            }
        }
        commit('setAuth', auth)
    },
    logout({commit}) {
        this.$axios.$get('/?logout=sim')
        commit('setAuth', {
            access_token: '',
            person_name: ''
        })
        Cookie.remove('auth')
    }
}
