<template>
    <div v-if="banners.length" class="top-banner">
        <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="banner in banners" :key="banner.id">
                <div class="text-banner-container">
                    <component :is="banner.link ? 'a' : 'div'" v-bind="banner.link ? {href: banner.link, target: banner.target} : ''">
                        <div class="text-banner" v-html="banner.description"></div>
                    </component>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
    import LazyLoad from "~/components/LazyLoad";
    import utils from "~/mixins/utils";
    import {Swiper, SwiperSlide} from "vue-awesome-swiper";

    export default {
        components: {
            Swiper,
            SwiperSlide
        },
        mixins: [
            utils
        ],
        props: {
            area: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                banners: []
            }
        },
        computed: {
            swiperOption() {
                return {
                    infinite: true,
                    loop: this.banners.length > 1,
                    centeredSlides: false,
                    slidesPerView: 1,
                    autoplay: {
                        delay: 2500,
                        disableOnInteraction: false
                    }
                }
            }
        },
        async fetch() {
            await this.$axios.$get(`api/v1/banner/area/?area=${this.area}`).then((res) => {
                if (res.status === 'success') {
                    this.banners = res.data
                }
            }).catch((e) => {
                console.error(e)
            })
        },
        fetchOnServer: false
    }
</script>
