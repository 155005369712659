<template>
    <div class="menu-mobile-container">
        <div class="collapse-menu">
            <div v-for="item in menu">
                <template v-if="typeof item.subitems === 'object' && item.subitems.length">
                    <a :href="item.href" :target="item.target" v-b-toggle="'mobile-menu' + item.id" @click.prevent="" class="collapse-menu-btn collapse-menu-name w-100 d-flex justify-content-between">
                        <div>{{item.name}}</div>
                        <div>
                            <svg class="collapse-menu-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="15" viewBox="0 0 51.431 73">
                                <defs>
                                    <filter id="Shape" x="0" y="0" width="51.431" height="73" filterUnits="userSpaceOnUse">
                                        <feOffset dy="3" input="SourceAlpha"/>
                                        <feGaussianBlur stdDeviation="3" result="blur"/>
                                        <feFlood flood-opacity="0.161"/>
                                        <feComposite operator="in" in2="blur"/>
                                        <feComposite in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <g transform="matrix(1, 0, 0, 1, 0, 0)">
                                    <path d="M1.737,10.125a5.931,5.931,0,0,1,8.388-8.388L31.694,23.306a5.931,5.931,0,0,1,0,8.388L10.125,53.263a5.931,5.931,0,1,1-8.388-8.388L19.112,27.5Z"/>
                                </g>
                            </svg>
                        </div>
                    </a>
                    <b-collapse v-for="subitem in item.subitems" :id="'mobile-menu' + item.id" :key="subitem.id">
                        <div v-if="!subitem.subitems.length">
                            <a :href="subitem.href" :target="subitem.target" @click="lgpdCheck(subitem.href, $event)" class="collapse-menu-btn level2 collapse-menu-name w-100 d-flex justify-content-between">
                                {{ subitem.name }}
                            </a>
                        </div>
                        <b-collapse v-for="subitem2 in subitem.subitems" :id="'mobile-menu' + item.id" :key="subitem2.id">
                            <b-button v-b-toggle="'mobile-menu' + subitem2.id" class="collapse-menu-btn-second-level collapse-menu name w-100 d-flex justify-content-between">
                                <div>{{subitem2.name}}</div>
                                <div>
                                    <svg class="collapse-menu-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="15" viewBox="0 0 51.431 73">
                                        <defs>
                                            <filter id="Shape" x="0" y="0" width="51.431" height="73" filterUnits="userSpaceOnUse">
                                                <feOffset dy="3" input="SourceAlpha"/>
                                                <feGaussianBlur stdDeviation="3" result="blur"/>
                                                <feFlood flood-opacity="0.161"/>
                                                <feComposite operator="in" in2="blur"/>
                                                <feComposite in="SourceGraphic"/>
                                            </filter>
                                        </defs>
                                        <g transform="matrix(1, 0, 0, 1, 0, 0)">
                                            <path d="M1.737,10.125a5.931,5.931,0,0,1,8.388-8.388L31.694,23.306a5.931,5.931,0,0,1,0,8.388L10.125,53.263a5.931,5.931,0,1,1-8.388-8.388L19.112,27.5Z"/>
                                        </g>
                                    </svg>
                                </div>
                            </b-button>
                        </b-collapse>
                    </b-collapse>
                </template>
                <template v-else>
                    <a :href="item.href" :target="item.target" @click="lgpdCheck(item.href, $event)" class="collapse-menu-btn collapse-menu-name w-100 d-flex justify-content-between">
                        <div>{{item.name}}</div>
                    </a>
                </template>
            </div>
        </div>
        <div class="px-4 py-3">
            <div v-html="$wdconfig.default.name" class="font-weight-bold mb-2"></div>
            <div>Fone: {{ $wdconfig.default.phone }}</div>
            <div v-html="address"></div>
            <div>{{ $wdconfig.default.email }}</div>
            <div>{{ $wdconfig.default.text_service_hours }}</div>
        </div>
        <div class="px-4 py-3">
            <a :href="$wdconfig.default.social.whatsapp.link" class="btn-whatsapp" target="_blank" rel="nofollow">
                <svg version="1.1" fill="#FFF" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="20" height="20">
                            <g>
                                <g>
                                    <path d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
                                        C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
                                        c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
                                        c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
                                        c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
                                        c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
                                        c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
                                        c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
                                        c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z"/>
                                </g>
                            </g>
                        </svg>
                <span v-html="$wdconfig.default.social.whatsapp.title"></span>
            </a>
        </div>
        <div class="px-3 py-2">
            <ul class="d-flex flex-wrap mb-1 list-unstyled">
                <li v-for="(social, key) in getListSocials" :key="key" class="mr-2 mb-2 social-network-item">
                    <a :href="social.link" :title="social.title" target="_blank" rel="nofollow"><img :src="social.image" :alt="social.name" :title="social.title" width="24" height="24" class="img-fluid"></a>
                </li>
            </ul>
        </div>
        <div class="payments_info py-4">
            <div v-if="cards.length" class="mb-5">
                <div class="title mb-2 text-uppercase text-center"> Pague com</div>
                <div class="d-flex flex-wrap justify-content-center align-items-center brands">
                    <div v-for="item in cards">
                        <img :src="imageUrl(item.image1)" :alt="item.name" :title="item.name" class="m-2">
                    </div>
                </div>
            </div>
            <div v-if="certificates.length" class="mb-3">
                <div class="title mb-2 text-uppercase text-center"> Certificado e segurança</div>
                <div class="d-flex flex-wrap justify-content-center align-items-center security">
                    <div v-for="item in certificates">
                        <template v-if="item.link">
                            <a :href="item.link" target="_blank">
                                <img :src="item.image" :alt="item.name" :title="item.name" class="img-fluid m-2">
                            </a>
                        </template>
                        <template v-else>
                            <img :src="item.image" :alt="item.name" :title="item.name" class="img-fluid m-2">
                        </template>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import socials from "~/mixins/socials";
    import utils from "~/mixins/utils";
    import lgpd from "~/mixins/lgpd";

    export default {
        mixins: [
            socials,
            utils,
            lgpd
        ],
        data() {
            return {
                menu: [],
                cards: []
            }
        },
        computed: {
            address() {
                let a = this.$wdconfig.default.address;
                if (a.street && a.number && a.city && a.neighborhood && a.state) {
                    return a['street'] + ', ' + a['number'] + ' ' + a['complement'] + '<br>'
                        + a['neighborhood'] + ', ' + a['city'] + '/' + a['state'];
                }
                return ''
            },
            certificates() {
                return [
                    {
                        name: 'Google',
                        link: process.browser ? `https://transparencyreport.google.com/safe-browsing/search?hl=pt-PT&url=${window.location.protocol}//${window.location.hostname}` : null,
                        image: '/img/certificates/security-site.png'
                    }, {
                        name: 'Site Seguro',
                        link: null,
                        image: '/img/certificates/security-site-2.png'
                    }, {
                        name: 'letsEncrypt',
                        link: null,
                        image: '/img/certificates/letsEncrypt.png'
                    }, {
                        name: 'SSL',
                        link: null,
                        image: '/img/certificates/selo-ssl-blindado.png'
                    }
                ]
            }
        },
        async fetch() {
            try {
                this.menu = [].concat(this.$store.state.menu_site, this.$store.state.menu_footer)
            } catch (e) {
                console.error(e)
            }

            await this.$axios.$get(`/api/v1/cms/?category_code=4&limit=100`).then((res) => {
                if (res.status === 'success') {
                    this.cards = res.data
                }
            }).catch((e) => {
                console.error(e)
            })
        }
    }
</script>
