import {merge} from 'lodash';

export const getters = {

}
export const state = () => {
    return {
        orders: [],
        order: {
            produtos: []
        },
        productsFilters: {
            brands: [],
        }
    }
}
export const mutations = {
    setOrder(state, order){
        state.order = order
    },
    changeQty(state, data){
        let product = state.order.produtos.find( elem => elem.codigo == data.product.codigo)
        product['quantidade'] = data.qty
    },
    setProductsFilters(state, brands) {
        state.productsFilters.brands = brands
    },
    setNewShippingPrice(state, response){
        state.order.valor_total = parseFloat(response.valor_total.replace('R$', '').replace('.', '').replace(',', '.'));
        state.order.valor_frete = parseFloat(response.valor_frete.replace('R$', '').replace('.', '').replace(',', '.'));
    }
}
export const actions = {
    loadOrder({commit, app}, callback = null) {
        this.$axios.$post(`?p=ecommerce_pedidos_api_v1&metodo=produtos_pedido`).then(res => {
            if (res.status === 'sucesso') {
                commit('setOrder', merge({}, {produtos: []}, res))
                if (typeof callback === 'function') {
                    callback()
                }
            }
        }).catch(e => {
            commit('setOrder', {})
            console.error(e)
        })
    }
}
