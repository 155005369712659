export default function ({app, req, res, query, error, store}) {
    if (!!store.state.config.modules.general.site_offline) {
        return error({
            statusCode: 503,
            message: ""
        })
    }

    let isMaintenance = store.state.config.modules.general.site_mode === 'maintenance';
    let isConstruction = store.state.config.modules.general.site_mode === 'construction';
    if (isMaintenance || isConstruction) {
        let constru = query.constru || query.construcao
        if (constru === 'sim') {
            app.$cookies.set('maintenance_mode', 'true')
            isMaintenance = false;
            isConstruction = false;
        }
        if (constru === 'nao') {
            app.$cookies.set('maintenance_mode', null)
        }
        if (app.$cookies.get('maintenance_mode') && constru !== 'nao') {
            isMaintenance = false;
            isConstruction = false;
        }
        if (isConstruction) {
            return error({
                statusCode: 200,
                message: 'Site em construção'
            })
        }
        if (isMaintenance) {
            return error({
                statusCode: 503,
                message: 'Site em manutenção'
            })
        }
    }
}
