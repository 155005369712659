import utils from '~/mixins/utils'

export default {
    mixins: [
        utils
    ],
    methods: {
        SubitemsCategory(code) {
            let fixedCategories = this.fixCategories.filter(elem => {
                return elem.codeFirstLevel == code
            })
            let fixedCategoriesLimited
            if (fixedCategories.length > 35) {
                fixedCategoriesLimited = this.limiterItems(fixedCategories, 35)
                fixedCategoriesLimited.push({
                    name: 'ver mais categorias',
                    code: '',
                    route: '/categorias'
                })
                return fixedCategoriesLimited
            }
            return fixedCategories
        }
    },
    computed: {
        fixCategories() {
            let categoriesFixed = []
            this.menu.map(category => {
                if (category.showcase == 'yes') {
                    category['totalSubitems'] = 0
                    if (category.subitems.length) {
                        category['totalSubitems'] = category.subitems.length
                        category['haveSubcategories'] = true
                        category['mainCategory'] = true
                        categoriesFixed.push(category)
                        category.subitems.map(secondLevelCategory => {
                            secondLevelCategory["codeFirstLevel"] = category.code
                            secondLevelCategory["codeParent"] = category.code
                            secondLevelCategory["index"] = 0
                            secondLevelCategory['route'] = `/categoria/${category.alias}/${secondLevelCategory.alias}`
                            categoriesFixed.push(secondLevelCategory)
                            if (secondLevelCategory.subitems.length) {
                                category['totalSubitems'] = category.totalSubitems + secondLevelCategory.subitems.length
                                let secondLevelCategoryLimited = this.limiterItems(secondLevelCategory.subitems, 5)
                                if (secondLevelCategoryLimited.length == 5) {
                                    secondLevelCategoryLimited.push({
                                        name: 'ver mais categorias',
                                        code: '',
                                        route: '/categorias'
                                    })
                                }
                                secondLevelCategoryLimited.map((thirdLevelCategory, index) => {
                                    secondLevelCategory['haveThirdSubcategoriesLevel'] = true
                                    thirdLevelCategory["codeFirstLevel"] = category.code
                                    thirdLevelCategory["index"] = index
                                    thirdLevelCategory["codeParent"] = secondLevelCategory.code
                                    thirdLevelCategory['route'] = secondLevelCategory.route + `/${thirdLevelCategory.alias}`
                                    categoriesFixed.push(thirdLevelCategory)
                                })
                            } else {
                                secondLevelCategory['haveThirdSubcategoriesLevel'] = false
                            }
                        })
                    } else {
                        category['haveSubcategories'] = false
                        category['mainCategory'] = true
                        categoriesFixed.push(category)
                    }
                }
            })
            return categoriesFixed
        }
    }
}
