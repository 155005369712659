import {merge} from 'lodash';

export const getters = {}
export const state = () => {
    return {
        products: {},
        params: {},
        brands: [],
        attr: [],
        product_base: '',
        product_code: 0
    }
}
export const mutations = {
    setBrands(state, brand) {
        state.brands = brand
    },
    setAttribute(state, attr) {
        state.attr = attr
    },
    setParams(state, params) {
        state.params = params;
    },
    setProductsCategory(state, products) {
        state.products = products;
    },
    setVariation(state, variation) {
        let variance = variation.product.variations.list.find(elem => elem.id == variation.id)
        variance.value = variation.event
    },
    // changeVariation(state, product) {
    //     let filter = {};
    //     let itens = [];
    //     let enabledItens = [];
    //     if (state.product_base === '') {
    //         state.product_base = product
    //     }
    //     product.price = state.product_base.price
    //     product.variations.list.forEach(function (element, index, array) {
    //         if (element.value > 0) {
    //             filter['variation' + element.id] = element.value;
    //         }
    //     })
    //     _.findIndex(product.variations.products_variations, function (o) {
    //         let index = [
    //             o
    //         ];
    //         if (_.findIndex(index, filter) === 0) {
    //             let parsedO = JSON.parse(JSON.stringify(o))
    //             itens.push(parsedO.code);
    //         }
    //     });
    //     let parsedProductVariations = JSON.parse(JSON.stringify(product))
    //     _.find(parsedProductVariations.variations.products, function (o) {
    //         if (o && o.code !== null) {
    //             if (itens.indexOf(o.code) !== -1) {
    //                 _.find(o.variations, function (u) {
    //                     enabledItens.push(u);
    //                 });
    //             }
    //         }
    //     });
    //     _.find(product.variations.list, function (o) {
    //         let ObjId = o.id;
    //         let ObjValue = o.value;
    //         let filter = false;
    //         _.find(product.variations.list, function (oO) {
    //             if (oO.id !== ObjId) {
    //                 if (oO.value > 0) {
    //                     filter = true;
    //                 }
    //             }
    //         });
    //         if (filter) {
    //             _.find(o.options, function (u) {
    //                 // u.text = u.text.replace(' - Não disponivel', '');
    //                 if (u.value > 0) {
    //                     u.disabled = null;
    //                     if (enabledItens.indexOf(u.value) === -1) {
    //                         u.disabled = true;
    //                         // u.text += ' - Não disponivel';
    //                     }
    //                 }
    //             });
    //         } else {
    //             _.find(o.options, function (u) {
    //                 // u.text = u.text.replace(' - Não disponivel', '');
    //                 u.disabled = null;
    //             });
    //         }
    //     });
    //     let variation_selecteds = true;
    //     _.find(product.variations.list, function (o) {
    //         if (o.value <= 0) {
    //             variation_selecteds = false;
    //         }
    //     });
    //     if (variation_selecteds === false) {
    //         state.product_code = 0;
    //         return;
    //     }
    //     let index = _.findIndex(product.variations.products_variations, filter);
    //     if (index < 0) {
    //         return;
    //     }
    //     state.product_code = product.variations.products_variations[index]['code']
    //     product.active = product.variations.products[state.product_code]['active']
    //     product.price = product.variations.products[state.product_code]['price']
    //     product.price_old = product.variations.products[state.product_code]['price_old']
    // }
    // setProductsFilters(state, brands) {
    //     state.productsFilters.brands = brands
    // }
}
export const actions = {
    loadCategoryProducts({commit}, params) {
        commit('setParams', params)
        let products_query = `api/v1/ecommerce/products/?page=${params.page}&sort_field=${params.sort_field}&sort_value=${params.sort_value}&limit=${params.limit}&filter_brands=${params.filter_brands}&show_categories=yes&show_attributes=true&show_brands=true`;
        if (params.category) {
            if (params.category_code) {
                products_query += `&category_code=${params.category_code}`
            } else {
                products_query += `&category_alias=${params.category_alias}`
            }
        }
        if (params.filter_brands) {
            products_query += `&filter_brands[]=${params.filter_brands}&show_brands=true`
        }
        if (params.q) {
            products_query = `?p=ecommerce_buscas_api_v1&q=${params.q}&page=${params.page}&sort_field=${params.sort_field}&sort_value=${params.sort_value}&filter_brands=${params.filter_brands}&limit=${params.limit}`
        }
        // if (this.$route.query.sort_field !== 'name') {
        //     products_query += `&sort[]=-active`
        // }
        // if (!this.$route.query.brand_code && !category.code) {
        //     return error({statusCode: 404, message: 'Categoria não encontrada'})
        // }
        this.$axios.$get(products_query).then((res) => {
            commit('setProductsCategory', res)
            commit('setBrands', res.metadata.brands)
            commit('setAttribute', res.metadata.attribute)
        }).catch((e) => {
            console.error(e)
        })
    }
}
