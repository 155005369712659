import Vue from "vue";
import cms from '~/data/schemas/cms';
import blogpost from '~/data/schemas/blogpost';
import banner from '~/data/schemas/banner';
import product from '~/data/schemas/product';
import categories from '~/data/schemas/categories.json'
import {merge} from 'lodash';
const wdmerge = {
    categories(obj){
        let merged = []
        if('data' in obj) {
            obj.data.map(el => {
                merged.push(merge({}, categories.data, el))
            })
        }
        return merged
    },
    cms(obj, list = false){
        if(list) {
            let merged = []
            if('list' in obj) {
                obj.list.map(el => {
                    merged.push(merge({}, cms.data, el))
                })
            }
            if('data' in obj) {
                obj.data.map(el => {
                    merged.push(merge({}, cms.data, el))
                })
            }
            return merged
        }
        let merged = merge({}, cms.data, obj.data)
        return merged
    },
    blogpost(obj, list = false){
        if(list) {
            let merged = []
            if (obj.data){
                obj.data.map(el => {
                    merged.push(merge({}, blogpost.data, el))
                })
            }
            return merged
        }
        let merged = merge({}, blogpost.data, obj.data)
        return merged
    },
    banner(obj){
        let merged = []
        if (obj.data){
            obj.data.map(el => {
                merged.push(merge({}, banner.data, el))
            })
        }
        return merged
    },
    product(obj){
        let merged = []
        if (obj.data){
            obj.data.map(el => {
                merged.push(merge({}, product.data, el))
            })
        }
        return merged
    }
}
// Vue.prototype.$wdmerge = wdmerge;

export default ({app}, inject) => {

    inject('wdmerge', wdmerge);
}
